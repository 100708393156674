// Here you can add other styles
.btn-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.p-datatable .p-datatable-header {
  padding: 0.5rem 1rem;
}

.status-switch {
  display: flex;
  align-items: center;
}

.status-switch p {
  margin: 0;
}

.ck.ck-balloon-panel.ck-powered-by-balloon[class*='position_border'] {
  display: none;
}

.p-badge.p-badge-warning {
  display: none;
}

.pointer {
  cursor: pointer !important;
}

.button-disabled {
  pointer-events: none;
  opacity: 0.5; 
}

// ========reduced the width of table row ====================
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.25rem 1rem !important;
}

// ======== strip color for every row of table ========
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
  background: #f5f3f3 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.25rem 1rem !important;
}
.p-component,
.p-component * {
  box-sizing: border-box;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
* {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
user agent stylesheet td {
  display: table-cell;
  vertical-align: inherit;
}
.p-datatable .p-datatable-tbody > tr {
  color: #000000 !important;
}
span.rounded-circle.p-1.border.pointer {
  position: relative;
  top: -44px;
  left: -1px;
  width: 33px;
  height: 33px;
  display: flex;
  align-items: 33px;
  padding: 0px !important;
  display: inline-grid;
  align-items: center;
  justify-content: center;
}

.p-inputtext .p-inputtext-sm {
  font-size: 0.875rem !important;
  padding: 0.5rem 0.25rem !important;
}
.p-inputtext {
  height: 38px !important;
}

.p-inputtext-sm {
  height: 38px !important;
}

.p-inputtextarea {
  height: 75px !important;
}

.p-multiselect {
  height: 38px !important;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  height: 35px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #4338ca !important;
  background: #eef2ff !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
}
.p-multiselect-panel {
  max-height: 600px !important;
  z-index: 1111 !important;
  // top: 220px !important;
}
.p-multiselect-panel .p-multiselect-items-wrapper {
  max-height: 500px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.1rem 1.05rem !important;
  font-size: 15px !important;
}

.p-multiselect-panel .p-multiselect-item .p-checkbox .p-checkbox-box {
  // border: 2px solid #ced4da;
  // background: #ffffff;
  width: 20px !important;
  height: 20px !important;
  // color: #495057;
  // border-radius: 6px;
  // transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  height: 35px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1.05rem !important;
  font-size: 15px !important;
}

.p-dropdown {
  height: 38px !important;
}

// .p-datepicker table {
//     width: 50% !important;
// }

.p-datepicker {
  min-width: 310px !important;
  width: 365px !important;
  height: 325px !important;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  height: 30px !important;
}
.p-datepicker table td > span {
  width: 22px !important;
  height: 22px !important;
}

.otpfeild {
  width: 45px;
  text-align: center !important;
  height: 60px;
  border: 1px solid #EDE8E9;
  border-radius: 8px;
}

.otp-feild input {
  font-family: Poppins;
  margin-bottom: 10.5px;
  margin-left: 14px;
}

.pagination-card {
  margin: 20px 20px;
}

