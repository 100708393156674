.bg-theme-dark {
  background-color: $dark-theme-color;
}

.bg-theme-light {
  background-color: $light-theme-color;
}

.text-theme-dark{
    color: $dark-theme-color;
}

.text-theme-light{
    color: $light-theme-color;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $dark-theme-color !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $dark-theme-color !important;
  background: $dark-theme-color !important;
}